.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 40;
  }
  
  .modal-main {
    position: absolute;
    background: whitesmoke;
    border-radius: 10px;
    width: 75%;
    height: 50vh;
    top:50%;
    left:60%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  
  .modal-main h2 {
    margin: 0.6rem auto;
    text-align: center;
    font-family: inherit;
    color: rgb(5, 47, 184);
  }

  .modal-main p {
    display: block;
    margin: 0.8rem  auto;
    
    font-size: larger;
    text-align: center;
    color: rgb(5, 47, 184);
  }

  .modal-main button {
      display: flex;
      margin: 0 auto;
      border: none;
      border-radius: 5px;
      justify-content: center;
      color: rgb(8, 34, 119);
      height: 2.4rem;
      
      text-align: center;
      text-decoration: none;
      font-size: 16px
  }

  .modal-main button:hover {
    background-color: rgb(9, 63, 134);
    color: rgba(192, 198, 212, 0.733);
  }

  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  @media (max-width: 1163px) {
.modal-main {
  width: 85%;
  height: 50vh;
  top:50%;
  left:50%;
}
  }
  @media (max-width: 568px) {
    .modal-main {
      
      width: 70%;
      height: 40vh;
      
    }

  }