.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
  color:cornflowerblue
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid rgb(141, 135, 135);
  border-color: rgb(5, 46, 107) transparent rgb(33, 108, 158) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay { 
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(211, 223, 235, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
